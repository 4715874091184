@use './abstracts/' as *;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

body {
    font-family: $fontFamily;
    color: $txtColor;
    font-size: $fontSizeNormal;
    line-height: 1.5;
    background-color: $bodyBg;
    overflow-x: hidden;

}

::-webkit-scrollbar {
    display: none;
}

a {
    text-decoration: none;
    color: unset;
}

img {
    width: 100%;
}

ul,
li {
    list-style-type: none;
}

.mb {
    margin-bottom: $spacing;

    @include tablet {
        margin-bottom: $mobileSpacing;
    }
}

.title {
    font-size: $fontSizeMedium;
    font-weight: 700;
}

.txt-success {
    color: $green;
}

.txt-danger {
    color: $danger;
}

// .bsampro {
//     background-image: url('../assets/images/LOGOSAMP.png');
//     background-repeat: no-repeat;


// }


////////......................

.react-tabs__tab--selected {
    background: none;
    border-color: none;
    color: #FF9066;
    border-radius: 5px 5px 0 0;
    border: none;
}

.announcement {
    width: 80%;

    input {
        width: 80%;
        border: solid #FF9066 1px;
        border-radius: 5px;
        height: 3rem;
        font-size: 18PX;

        :focus {
            outline: none;
            border: solid #FF9066 1px;

        }

    }
}

//search............................................................
.searchbox {
    display: flex;
    align-items: center;
    justify-content: space-between;


    .group {
        display: flex;
        line-height: 28px;
        align-items: center;
        position: relative;
        width: 320px;
    }

    .input {
        width: 100%;
        height: 40px;
        line-height: 28px;
        padding-left: 2.5rem;
        border: 2px solid #FF9066;
        border-radius: 8px;
        outline: none;
        background-color: #f3f3f4;
        color: #0d0c22;
        transition: .3s ease;
        margin: 10px 0;
    }

    .input::placeholder {
        color: $txtColor;
    }

    .input:focus,
    input:hover {
        outline: none;
        border-color: #FF9066;
        background-color: #fff;
        box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
    }

    .icon {
        position: absolute;
        left: 1rem;
        fill: #FF9066;
        width: 1rem;
        height: 1rem;
    }
}