@use '../../scss/abstracts/' as *;

.login {
    background-color: $bodyBg;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .login-page {
        width: 360px;
        padding: 8% 0 0;
        margin: auto;


        .form {
            position: relative;
            padding: 1rem;
            border-radius: 5px;
            background-color: #FFFFFF;
            max-width: 360px;
            margin: 0 auto 100px;
            padding: 45px;
            text-align: center;
            box-shadow: 0 0 30px 0 rgba(203, 203, 203, 0.479), 0 2px 2px 0 rgba(139, 138, 138, 0.2);

            h2 {
                padding: 1rem 0;
            }

            input {
                font-family: "Roboto", sans-serif;
                outline: 0;
                background-color: $bodyBg;
                width: 100%;
                border: 0;
                border-radius: 5px;
                margin: 0 0 15px;
                padding: 15px;
                box-sizing: border-box;
                font-size: 14px;
            }

            button {
                font-family: "Roboto", sans-serif;
                text-transform: uppercase;
                outline: 0;
                background-color: $orange;
                width: 100%;
                border: 0;
                border-radius: 5px;
                padding: 15px;
                color: #FFFFFF;
                font-size: 14px;
                -webkit-transition: all 0.3 ease;
                transition: all 0.3 ease;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background-color: $orange;
                }

                &:active {
                    background-color: $orange;
                }

                &:focus {
                    background-color: $orange;
                }
            }

            .message {
                margin: 15px 0 0;
                color: #b3b3b3;
                font-size: 12px;

                a {
                    color: $orange;
                    text-decoration: none;
                }
            }

        }



    }
}