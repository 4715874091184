@use '../scss/abstracts/' as *;

@forward './global';
@forward './flex-grid';

.on-loading {
    width: 18px;
    height: 18px;
    border: 2px solid #286c03;
    border-bottom-color: #e6e6d5;
    border-radius: 50%;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-animation: rotation 1s linear infinite;
    animation: rotation 1s linear infinite;
    margin-right: 5px;
}

.pending-warning{
 background-color: #b6ad27;
 padding: 2px 6px;
 color: #ffffff;
 border-radius: 10px;
 font-size: 12px;
}

.av-gr-status {
    color: #286c03;
}

.un-rd-status {
    color: red;
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.date-range-container {
    display: flex;
    align-items: center;

    .date-range {
        margin-left: 10px;
        display: flex;
        align-items: center;

        input {
            width: 100%;
            height: 2.5rem;
            margin: 0.2rem 0;
            padding: 0 0.3rem;
            border: solid $txtColor 1px;
            border-radius: 5px;
            font-size: $fontSizeNormal;
            color: $txtColor;

            &:active {
                border: $orange ;
            }

            &:focus {
                border: $orange;
            }
        }
    }

}

.fn-apt-list {
    background-color: #ffffff;
    border-radius: 10px;
    margin: 10px;
    padding: 15px;
    cursor: pointer;
}
.fn-make-active {
    background-color: #ff9066 !important;
    color: #ffffff !important;
}

.fn-apts-cont {
    margin: 0 10px 20px -12px !important;
}

.ft-apts-pagination {
    margin: 0 0 70px;
}