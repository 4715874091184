@use '../../scss/abstracts/' as *;

.table {
    @extend %table !optional;
    @extend %table-condensed !optional;
    @extend %table-bordered !optional;

}

@-ms-viewport {
    width: device-width;
    zoom: 1.0;
}

@-o-viewport {
    width: device-width;
    zoom: 1.0;
}

@viewport {
    width: device-width;
    zoom: 1.0;
}

html {
    overflow-y: auto;
}

img,
audio,
video,
canvas {
    max-width: 100%;
}

/*  Sets Body Width */
.container {
    max-width: $container-max-width;
    width: $container-width;
    margin-left: auto;
    margin-right: auto;
}


table {
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}


.table,
%table {
    width: 100%;
    margin-bottom: $table-margin-bottom;
}

.table th,
%table th,
.table td,
%table td {
    padding: $table-cell-padding;
    text-align: $table-cell-text-align;
    border-top: $table-cell-border-top;
    cursor: pointer;

    &:hover {
        $table-border-color: #ba0404;
    }
}


.table th,
%table th {
    font-weight: $table-header-font-weight;
    vertical-align: bottom;
    background-color: $txtColor;
    color: $white;
    height: 2rem;
}

.table tr,
%table tr {
    &:hover {
        background-color: $bodyBg;

    }
}

/*  Aligns cell text to the top of each cell. */
.table td,
%table td {
    white-space: nowrap;
    vertical-align: top;
    font-size: $fontSizeTable;
    overflow: hidden;
    max-width: 18rem;

    img {
        width: 5rem;
    }

    select {
        border: none;
        color: $txtColor;
        font-size: medium;

        option {
            border: none;
            box-shadow: 21px 4px 94px -67px rgba(0, 0, 0, 0.65);
            -webkit-box-shadow: 21px 4px 94px -67px rgba(0, 0, 0, 0.65);
            -moz-box-shadow: 21px 4px 94px -67px rgba(0, 0, 0, 0.65);
        }

        &:hover {
            background-color: $bodyBg;
        }
    }
}

/*  Removes the border-top from the first row. */
.table thead:first-child tr th,
%table thead:first-child tr th,
.table thead:first-child tr td,
%table thead:first-child tr td {
    border-top: 0;
}

/*  Adds border-top between two table-body sections. */
.table tbody+tbody,
%table tbody+tbody {
    border-top: $table-body-border-top;
    margin: auto;
}

/*  Reduces cell padding */
.table-condensed th,
%table-condensed th,
.table-condensed td,
%table-condensed td {
    padding: $table-condensed-padding;

}

/* Adds borders and border-radius. */
.table-bordered,
%table-bordered {
    border: $table-bordered-border;
    border-collapse: separate;
    *border-collapse: collapsed;
    border-radius: $table-bordered-border-radius;
}

.table-bordered th+th,
%table-bordered th+th,
.table-bordered td+td,
%table-bordered td+td,
.table-bordered th+td,
%table-bordered th+td,
.table-bordered td+th,
%table-bordered td+th {
    border-left: $table-border-left;
}

.table-bordered thead:first-child tr:first-child th,
%table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
%table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td,
%table-bordered tbody:first-child tr:first-child td {
    border-top: 0;
}

.table-bordered thead:first-child tr:first-child th:first-child,
%table-bordered thead:first-child tr:first-child th:first-child,
.table-bordered tbody:first-child tr:first-child td:first-child,
%table-bordered tbody:first-child tr:first-child td:first-child {
    border-radius: $table-bordered-child-radius 0 0 0;
}

.table-bordered thead:first-child tr:first-child th:last-child,
%table-bordered thead:first-child tr:first-child th:last-child,
.table-bordered tbody:first-child tr:first-child td:last-child,
%table-bordered tbody:first-child tr:first-child td:last-child {
    border-radius: 0 $table-bordered-child-radius 0 0;
}

.table-bordered thead:last-child tr:last-child th:first-child,
%table-bordered thead:last-child tr:last-child th:first-child,
.table-bordered tbody:last-child tr:last-child td:first-child,
%table-bordered tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 $table-bordered-child-radius;
}

.table-bordered thead:last-child tr:last-child th:last-child,
%table-bordered thead:last-child tr:last-child th:last-child,
.table-bordered tbody:last-child tr:last-child td:last-child,
%table-bordered tbody:last-child tr:last-child td:last-child {
    border-radius: 0 0 $table-bordered-child-radius 0;
}

table {


    .edit {
        font-size: $fontSizeMedium;
        color: $green;
        // margin: 0 5px;
    }

    .view {
        font-size: $fontSizeMedium;
        color: $purple;


        &::before {
            content: attr(title);
            visibility: hidden;
            opacity: 0;
            width: 140px;
        }

        &:hover::before {
            opacity: 1;
            visibility: visible;
        }
    }

    .delete {
        font-size: $fontSizeMedium;
        color: $danger;

        &:hover {
            color: $danger;
        }

    }

    .addnew {
        font-size: $fontSizeMedium;
        color: $green;
        margin: 0 5px;

        &:hover {
            color: $green;
        }
    }

    .remove {
        font-size: $fontSizeMedium;
        color: $danger;
        margin: 0 0 0 2px;

        &:hover {
            color: $danger;
        }
    }

    .publish {
        font-size: $fontSizeMedium;
        color: $green;
    }
}

// Button for pagination container
.pagination-btn {
    display: flex;
    justify-content: flex-end;
}