$bodyBg: #F3F4FA;
$mainBg: #fff;
$mainColor: #b40f03af;
$txtColor: #4E4D4F;
$purple: #8624DB;
$orange: #FF9066;
$white: #fff;
$black: #000;
$green: #4CAF50;
$danger: #DB190C;

$fontFamily: 'Roboto', sans-serif;

$fontSizeNormal: 1.125rem;
$fontSizeTable: 1rem;
$fontSizeMedium: 1.25rem;
$fontSizeLarge: 1.5625rem;

$sideBarWidth: 300px;
$sideBarLogoHeight: 120px;
$spacing: 40px;
$mobileSpacing: 24px;
$borderRadius: 15px;

$mobile-width: 600px;
$tablet-width: 1300px;



$table-border-color: #b8b8b8;
$container-width: 88%;
$container-max-width: 40em;
$table-border-width: .071428571em;
$table-border-style: solid;
$table-margin-bottom: 1.529411765em;
$table-cell-padding: .470588235em;
$table-cell-text-align: left;
$table-cell-border-top: $table-border-width $table-border-style $table-border-color;
$table-header-font-weight: bold;
$table-body-border-top: .142857143em $table-border-style $table-border-color;
$table-condensed-padding: .294117647em;
$table-bordered-border: $table-border-width $table-border-style $table-border-color;
$table-bordered-border-radius: .470588235em;
$table-border-left: $table-border-width $table-border-style $table-border-color;
$table-bordered-child-radius: .470588235em;