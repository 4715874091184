@use '../../scss/abstracts/' as *;

.alert-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;

  .alert-shadow {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0, 0.42);
    width: 100%;
    height: 100vh;
    z-index: 100;
  }
}

.alert-center {
  min-width: 35%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: white;
  max-height: 85vh;
  border-radius: 10px;
  z-index: 100;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.alert-header {
  border-bottom: solid $txtColor;
  text-align: center;
  width: 100%;
  height: 70px;
  padding: 0 20px;
  color: $txtColor;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-content {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  p {
    font-weight: 500;

    span {
      font-weight: 300;
    }
  }

  button {
    width: 8rem;
    height: 2.5rem;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin: 0 0.4rem;
  }
}

@media (max-width: 1000px) {
  .alert-center {
    width: 60% !important;
  }
}

@media (max-width: 870px) {
  .alert-center {
    width: 65% !important;
  }
}

@media (max-width: 700px) {
  .alert-center {
    width: 70% !important;
  }
}

@media (max-width: 500px) {
  .alert-center {
    width: 80% !important;
  }
}