@use '../../../scss/abstracts/' as *;

.profile {
    margin: auto;
    display: flex;
    justify-content: center;
    height: 10rem;
    width: 10rem;
    padding: 1rem;

}

.profile_content {
    padding: 1rem;

    .row {
        padding: 0.5rem 0.3rem;
        border-bottom: solid rgb(226, 226, 226) 1px;
        align-items: center;
    }
}

.check {
    margin: 1rem;
}