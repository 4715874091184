@use '../../scss/abstracts/' as *;




.drop-down-container {
    width: 120px;

    .line-to-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: $fontSizeTable;
        font-style: $fontFamily;
        color: $txtColor;
    }

    .line-to-drop-down {
        width: 140px;
        background-color: $mainBg;
        display: flex;
        flex-direction: column;

        position: absolute;
        z-index: 100;
        box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
        border-radius: 5px;

    }

    .line-to-drop-down button {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #e4e4e4;
        cursor: pointer;
        padding: 6px 0;
        font-size: $fontSizeTable;
        font-style: $fontFamily;
        color: $txtColor;
    }

    .line-to-drop-down button:last-child {
        border-bottom: none;
    }

    .line-to-drop-down button:hover {
        background-color: $orange;
        color: $mainBg;
    }
}