@use '../../scss/abstracts/' as *;

.modal-container {
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: flex;
  width: 100%;

  .modal-shadow {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0, 0.42);
    width: 100%;
    height: 100vh;
    z-index: 100;
  }
}

.print {
  padding: 2rem;
}

.modal-center {
  min-width: 80%;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: white;
  max-height: 85vh;
  border-radius: 10px;
  padding: 20px;
  z-index: 100;
  overflow-y: auto;
  // display: flex;
  // justify-content: center;
}

@media (max-width: 1000px) {
  .modal-center {
    width: 60% !important;
  }
}

@media (max-width: 870px) {
  .modal-center {
    width: 65% !important;
  }
}

@media (max-width: 700px) {
  .modal-center {
    width: 70% !important;
  }
}

@media (max-width: 500px) {
  .modal-center {
    width: 80% !important;
  }
}