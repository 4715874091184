@use '../../scss/abstracts/' as *;

.topnav {
    cursor: pointer;
    @include flex(center, space-between);
    margin-bottom: $spacing;

    @include tablet {
        margin-bottom: $mobileSpacing;
    }
}

.sidebar-toggle {
    --size: 40px;
    width: var(--size);
    height: var(--size);
    @include flex(center, center);
    cursor: pointer;
    display: none;

    @include tablet {
        display: flex;
    }

    i {
        font-size: 3rem;
        color: $mainColor;
    }
}

// User Info styles

.user-info {
    @include flex(center, flex-start);
    background-color: $mainBg;
    border-radius: $borderRadius;
    height: 50px;
    width: max-content;

    &__img {
        --size: 50px;
        width: var(--size);
        height: var(--size);
        border-radius: $borderRadius;

        img {
            border-radius: $borderRadius;
        }
    }

    &__name {
        padding: 0 $spacing;
        font-size: $fontSizeMedium;
        font-weight: 700;

        @include tablet {
            padding: 0 $mobileSpacing;
        }
    }
}