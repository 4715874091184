 @use '../../scss/abstracts/' as *;

 .icons {
     display: flex;
     justify-content: flex-end;

     .myprint {
         cursor: pointer;
     }

     .close {
         cursor: pointer;
         font-size: 30px;
     }
 }

 .singleview-header {
     border-bottom: solid rgb(207, 207, 207) 0.2px;

     width: 100%;

     color: $txtColor;
     align-items: center;
 }

 .singleview-content {
     padding: 20px 0;


     p {
         font-weight: 500;


         span {
             font-weight: 300;
         }
     }

     button {
         width: 7rem;
         height: 2.5rem;
         font-size: 16px;
         cursor: pointer;
         border-radius: 5px;
         margin: 0 0.4rem;
     }
 }

 .print {
     padding: 2rem;
 }

 @media (max-width: 1000px) {
     .singleview-center {
         width: 60% !important;
     }
 }

 @media (max-width: 870px) {
     .singleview-center {
         width: 95% !important;
     }
 }

 @media (max-width: 700px) {
     .singleview-center {
         width: 95% !important;
     }
 }

 @media (max-width: 500px) {
     .singleview-center {
         width: 100% !important;
     }
 }