@use '../../scss/abstracts/' as *;
@use 'sass:math';

.overall-list {
    &>*~* {
        margin-top: math.div($spacing, 4);
    }

    &__item {
        @include flex(center, flex-start);

        &__icon {
            @include flex();
            --size: 50px;
            width: var(--size);
            height: var(--size);
            border-radius: 50%;
            background-color: $bodyBg;
            margin-right: $spacing;

            i {
                font-size: 1.9rem;
                color: $black;
            }
        }

        &__info {
            @include flex(flex-start, space-between);
            flex-direction: column;
        }
    }
}