@use '../../scss/abstracts/' as *;
@use 'sass:math';

.sidebar {
    @include flex(stretch, flex-start);
    flex-direction: column;
    color: $txtColor;
    width: $sideBarWidth;
    background-color: $mainBg;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 100;

    @include tablet {
        width: 100vw;
        background-color: $mainColor;
        color: $white;
        z-index: 1;
    }

    &__logo {
        @include flex();
        height: $sideBarLogoHeight;


        @include tablet {
            justify-content: space-between;
            height: math.div($sideBarLogoHeight, 1.8);
            padding: 0 $mobileSpacing;
            margin-bottom: $mobileSpacing;
        }

        img {
            --wsize: 130px;
            --hsize: 40px;
            width: var(--wsize);
            height: var(--hsize);
        }

        .sidebar-close {
            cursor: pointer;
            display: none;

            i {
                font-size: 2rem;
            }

            @include tablet {
                display: block;
            }
        }
    }

    &__menu {
        @include flex(stretch, flex-start);
        flex-direction: column;
        flex-grow: 1;

        &__item {

            @include flex(center, flex-start);
            position: relative;
            margin-bottom: 1rem;
            padding-left: 10%;
            transition: color 0.3s ease-in-out;

            @include tablet {
                padding-left: $mobileSpacing;
            }

            // &:hover {
            //     color: $mainColor;

            //     @include tablet {
            //         color: $white;
            //     }
            // }

            &.active {
                font-weight: 800;
                color: $mainColor;

                @include tablet {
                    color: $white;
                }

                &::before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 5px;
                    left: 0;
                    top: 0;
                    background-color: $mainColor;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;

                    @include tablet {
                        background-color: $white;

                    }
                }
            }

            &__icon {
                --size: 40px;
                width: var(--size);
                height: var(--size);
                margin-right: 1rem;
                @include flex(center, center);

                @include tablet {
                    margin-right: 1rem;
                }

                i {
                    font-size: 1.9rem;
                }
            }

            &:last-child {
                margin-top: auto;
                cursor: pointer;

                &:hover {
                    color: $mainColor;

                    @include tablet {
                        color: $white;
                    }
                }
            }

            &__childrens {
                display: none;
                width: 12rem;


                &.active {
                    display: flex;
                    justify-content: flex-start;
                    position: absolute;
                    flex-direction: column;
                    z-index: 1;

                    margin: 8rem 0 0 9.5rem;
                    background-color: $mainBg;
                    border-radius: 20px;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;


                }
            }

            .arrow {
                cursor: pointer;

                &:hover {
                    color: $mainColor;
                }
            }

            p {
                cursor: pointer;

                &:hover {
                    color: $mainColor;
                }
            }

        }
    }
}