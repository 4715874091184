@use '../../scss/abstracts/' as *;

.userform {
    width: 90%;
    margin: auto;

    h3 {
        padding: 0 0 1rem 0;
    }
}

.inputform {
    display: flex;
    align-content: center;
    flex-direction: column;

    input,
    textarea {
        width: 100%;
        height: 2.5rem;
        margin: 0.2rem 0;
        padding: 0 0.3rem;
        border: solid $txtColor 1px;
        border-radius: 5px;
        font-size: $fontSizeNormal;
        color: $txtColor;

        &:active {
            border: $orange ;
        }

        &:focus {
            border: $orange;
        }
    }

    select {
        width: 100%;
        height: 2.5rem;
        height: 2.5rem;
        margin: 0.2rem 0;
        padding: 0 0.3rem;
        border: solid $txtColor 1px;
        border-radius: 5px;
        font-size: $fontSizeNormal;
        color: $txtColor;
    }

}

.form-button {
    display: flex;
    justify-content: space-around;
    padding: 1rem 0;
}